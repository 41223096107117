import React from 'react';
import { createBrowserRouter,RouterProvider } from 'react-router-dom';
import Main from './pages/Main';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import VitaminE from './pages/service/VitaminE';
import BhringrajHairOil from './pages/service/BhringrajHairOil';
import OnionHairOil from './pages/service/OnionHairOil';
import PimpleSerum from './pages/service/PimpleSerum';
import SkinLightening from './pages/service/SkinLightening';
import VitaminC from './pages/service/VitaminC';






const routes = createBrowserRouter([
  {
    path: '/',
    element: <Main />,
    children: [
      {
        path: "",
        element: <Home />
      },
      {
        path: '/about',
        element: <About/>
      },
      {
        path: '/contact',
        element: <Contact/>
      } ,{
        path: '/services',
        element: <Services/>
      } ,{
        path: '/bhringraj_hair_oil',
        element: <BhringrajHairOil/>
      } ,{
        path: '/onion_hair_oil',
        element: <OnionHairOil/>
      } ,{
        path: '/pimple_serum',
        element: <PimpleSerum/>
      } ,{
        path: '/skin_lightening',
        element: <SkinLightening/>
      },{
        path: '/vitamin_c',
        element: <VitaminC/>
      },{
        path: '/vitamin_e',
        element: <VitaminE/>
      }
    ]
  },
  
]
)

const App =()=> {
  return (

     <RouterProvider router={routes} />

  )
}

export default App;
