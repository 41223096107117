import React from 'react';
import Abouts from '../component/Abouts';

const About = () =>  {
  return (
    <>
    <Abouts/>
    </>
  )
}

export default About;