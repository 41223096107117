import React from 'react';


const PimpleSerum = () => {
    return (
        <div className='px-6  mycontainer md:px-4 mb-8'>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
                <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/1p.jpg" alt="" /></div>
                <div className='flex px-2 mt-10 md:mt-0 flex-col gap-3'>
                <p className="text-lg capitalize text-[#000]">
            Nuevocares – pimple serum
          </p>
    <hr />
    <p className='text-sm text-[#777]'>LIGHTWEIGHT ANTI PIGMENTATION SERUM: Alpha Arbutin added in this de pigmentation serum is one of the advanced skin lightening ingredient that is clinically proven to reduce hyperpigmentation & blemishes.This serum is lighter than traditional pigmentation removal creams thus absorbs quickly leaving no sticky residue. It is safe to be used daily as an anti-pigmentation serum.
REDUCES TANNING & SPOTS: Reduces dark spots, acne marks (or pimple marks), resulting in even skin tone. Alpha Arbutin added in this serum by DSM is proven to reduce tanning after UV exposure. A regular use of this serum can help in reducing skin tan, dark spots & photo damage along with broad spectrum sunscreen.
EFFECTIVE & SAFE De-PIGMENTATION SERUM: It is one of safest and effective skin lightening active compared to other actives such as Kojic Acid used in traditional pigmentation removal cream.. It inhibits melanin production without causing cell cytotoxicity thus is safe to be used as anti-pigmentation daily serum for longer durations unlike other skin lightening or brightening ingredients.
CLEAN & TRANSPARENT BEAUTY: This serum is (i) Fragrance-free (ii) Silicones-free (iii) Sulfates-free (iv) Parabens-free (v) Essential Oils-free and (vi) Dyes-free. Also, it is non-comedogenic, oil-free, and hypoallergenic. Formulated at pH 4.7 - 5.2 with key ingredient sourced from leading global supplier Royal DSM, Netherlands.
FOR ALL SKIN TYPES: Alpha Arbutin is suitable for all skin type. With added Hyaluronic acid and Aloe Vera it is suitable for dry or sensitive skin as well.</p>
</div>

            </div>
            <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg capitalize text-[#000]">
            Nuevocares – pimple serum
          </p>
    <hr />
    <p className='text-sm text-[#777]'>LIGHTWEIGHT ANTI PIGMENTATION SERUM: Alpha Arbutin added in this de pigmentation serum is one of the advanced skin lightening ingredient that is clinically proven to reduce hyperpigmentation & blemishes.This serum is lighter than traditional pigmentation removal creams thus absorbs quickly leaving no sticky residue. It is safe to be used daily as an anti-pigmentation serum.
REDUCES TANNING & SPOTS: Reduces dark spots, acne marks (or pimple marks), resulting in even skin tone. Alpha Arbutin added in this serum by DSM is proven to reduce tanning after UV exposure. A regular use of this serum can help in reducing skin tan, dark spots & photo damage along with broad spectrum sunscreen.
EFFECTIVE & SAFE De-PIGMENTATION SERUM: It is one of safest and effective skin lightening active compared to other actives such as Kojic Acid used in traditional pigmentation removal cream.. It inhibits melanin production without causing cell cytotoxicity thus is safe to be used as anti-pigmentation daily serum for longer durations unlike other skin lightening or brightening ingredients.
CLEAN & TRANSPARENT BEAUTY: This serum is (i) Fragrance-free (ii) Silicones-free (iii) Sulfates-free (iv) Parabens-free (v) Essential Oils-free and (vi) Dyes-free. Also, it is non-comedogenic, oil-free, and hypoallergenic. Formulated at pH 4.7 - 5.2 with key ingredient sourced from leading global supplier Royal DSM, Netherlands.
FOR ALL SKIN TYPES: Alpha Arbutin is suitable for all skin type. With added Hyaluronic acid and Aloe Vera it is suitable for dry or sensitive skin as well.</p>
</div>
                <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/1.jpg" alt="" /></div>
            </div>

        </div>
    );
}

export default PimpleSerum;
