import React from "react";
import { BiCurrentLocation, BiLogoWhatsapp } from "react-icons/bi";

const Contacts = () => {
  return (
    <div className="px-2 w-mycontainer md:px-0 mb-8">
      <h1 className="text-center text-2xl  mt-5">Contact Us</h1>
      <hr />
      <div className="container p-6 my-2 mx-auto md:px-6">
        <section className="mb-10">
          <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div className="flex flex-wrap items-center">
              <div className="block w-full shrink-0 grow-0 basis-auto ">
                <div className="h-[500px] w-full">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3680.824933240373!2d75.83047813707098!3d22.69756012846955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fc4ad6010a7f%3A0x77f4164d4e5f342a!2s624%2C%20Sudama%20Nagar%20Main%20Rd%2C%20Sector%20D%2C%20Bank%20Colony%2C%20Indore%2C%20Madhya%20Pradesh%20452009!5e0!3m2!1sen!2sin!4v1702354629660!5m2!1sen!2sin" className="left-0 top-0 sm:p-4 md:p-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
                    frameborder="0"
                    allowfullscreen></iframe>
                 
                </div>
              </div>

              <div className="grid md:grid-cols-3 mt-10 gap-10 md:gap-[100px]">
                <div className=" px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                  <div className="flex items-start">
                    <div>
                      <div className="inline-block rounded-md bg-primary-100 p-4 text-primary">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="2"
                          stroke="currentColor"
                          className="h-6 w-6"
                        >
                          <path
                            stroke-linecap="round"
                            className="animate-ping "
                            stroke-linejoin="round"
                            d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold dark:text-white">
                        Contact Us
                      </p>
                      <p className="text-neutral-500 dark:text-neutral-200">
                      nuevocare@gmail.com
                      </p>
                      <p className="text-neutral-500 dark:text-neutral-200">
                        +91 8770811689
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ml-6 grow">
                  <div className="flex">
                    <div>
                      <div className=" rounded-md bg-primary-100 p-4 text-primary">
                        <BiCurrentLocation className="text-[25px] animate-ping " />
                      </div>
                    </div>
                    <div>
                      <p className="mb-2 font-bold dark:text-white">Address</p>
                      <p>
                      624, Sudama Nagar Main Rd, Sector D, Bank Colony, Indore, Madhya Pradesh 452009,

                        India
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" px-3 md:w-6/12 md:px-6 lg:w-full xl:w-6/12 xl:px-12">
                  <div className="align-start flex">
                    <div>
                      <div className="rounded-md bg-primary-100 p-4 text-primary">
                        <BiLogoWhatsapp className="animate-ping text-[25px]" />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <a href="https://api.whatsapp.com/send/?phone=918770769155&text=Hello%2C+I+am+interested+in your services">
                        <p className="mb-2 font-bold dark:text-white">
                          ChatNow
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          +91 8770769155
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-200">
                          on whatsapp
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </section>
      </div>
    </div>
  );
};

export default Contacts;
