import React from 'react';



const VitaminE = () => {
    return (
        <div className='px-6 mycontainer  md:px-4 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/2p.jpg" alt="" /></div>
            <div className='flex mt-10 md:mt-0 px-2 flex-col gap-3'>
            <p className="text-lg capitalize text-[#000]">
            Nuevocares – Vitamin E serum
          </p>
                <hr />
                <p className='text-sm text-[#777]'>riple Vitamin E Brilliance: Our 10% Vitamin C serum contains Triple Vitamin C which effectively treats dullness and hyperpigmentation, unveiling even toned, glowing skin. Unique blend of triple Vitamin C brings together brightening properties, antioxidant benefits, and collagen production for a radiant and youthful look.
Sicilian Blood Orange Revitalization: Infused with the rejuvenating essence of Sicilian Blood Orange, our serum not only boosts collagen production but also enhances firmness and re-energizes tired skin. Your skin regains a more youthful and refreshed appearance.
Niacinamide Brilliance: With 5% Niacinamide, our serum helps fade dark spots and evens out skin tone. Being both AM/PM use, our serum treats your skin to reveal flawlessly radiant complexion.
Skin Nourishment: Enriched with Vitamin E, our serum not only prevents further skin damage but also increases the potency of Vitamin C. One serum gives you combined benefits of sun damage repair, free radical protection, and the reduction of fine lines and wrinkles.
Clean Formulations, Cleaner Ingredients: Our ingredients are derived naturally and we ensure that our products are Sulphate Free, Mineral-Oil Free, Essential Oil Free, Paraben Free, Cruelty-Free,GMO-Free.</p>

            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg capitalize text-[#000]">
            Nuevocares – Vitamin E serum
          </p>
                <hr />
                <p className='text-sm text-[#777]'>    <p className='text-sm text-[#777]'>riple Vitamin C Brilliance: Our 10% Vitamin C serum contains Triple Vitamin E which effectively treats dullness and hyperpigmentation, unveiling even toned, glowing skin. Unique blend of triple Vitamin E brings together brightening properties, antioxidant benefits, and collagen production for a radiant and youthful look.
Sicilian Blood Orange Revitalization: Infused with the rejuvenating essence of Sicilian Blood Orange, our serum not only boosts collagen production but also enhances firmness and re-energizes tired skin. Your skin regains a more youthful and refreshed appearance.
Niacinamide Brilliance: With 5% Niacinamide, our serum helps fade dark spots and evens out skin tone. Being both AM/PM use, our serum treats your skin to reveal flawlessly radiant complexion.
Skin Nourishment: Enriched with Vitamin E, our serum not only prevents further skin damage but also increases the potency of Vitamin C. One serum gives you combined benefits of sun damage repair, free radical protection, and the reduction of fine lines and wrinkles.
Clean Formulations, Cleaner Ingredients: Our ingredients are derived naturally and we ensure that our products are Sulphate Free, Mineral-Oil Free, Essential Oil Free, Paraben Free, Cruelty-Free,GMO-Free.</p></p>
                
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/2.jpg" alt="" /></div>
        </div>

    </div>
    );}

export default VitaminE;
