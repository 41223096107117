import React from 'react';
const Abouts = () => {
    return (
        <div className='  md:px-0 mt-10'>
            <div className='grid px-10  text-justify md:grid-cols-2 mt-[120px]'>
                <div style={{boxShadow:' blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px'}} className='md:w-[400px] sm:w-[200px] md:mb-0 mb-6 flex justify-center items-center' ><img src="image/1.jpg" alt="" /></div>
                <div className='flex px-2 flex-col gap-3'>
                    <p className='text-lg text-[#000]'>About NuevoCare Products</p>
                    <hr />
                    <p className='text-sm text-[#777]'>Welcome to NuevoCare Products, where the beauty of your hair is our passion! We believe that every individual deserves to embrace their natural beauty, and we are dedicated to providing innovative and nourishing hair care solutions.
                    At NuevoCare, our mission is to empower individuals to achieve healthy, vibrant, and luscious locks through the use of premium hair oils. We understand the significance of hair in expressing individuality and confidence, and we strive to enhance the natural beauty of your tresses.</p>
                    
                </div>
            </div>
            <div className='grid px-10 text-justify md:grid-cols-2 mt-10'>
                <div className='flex  md:mb-0 mb-6 px-2 flex-col gap-3'>
                    <p className='text-lg  text-[#000]'>Customer-Centric Approach</p>
                    <hr />
                    <p className='text-sm text-[]'>Your satisfaction is our priority. We believe in transparency and strive to build lasting relationships with our customers. Our team of experts is always available to provide personalized advice and guidance on selecting the right hair oil for your needs. We are here to support you on your journey to healthier, more beautiful hair.
                    At NuevoCare, we stay ahead of the latest trends and advancements in hair care. Our commitment to continuous research and innovation allows us to develop cutting-edge formulations that cater to the evolving needs of our customers. We take pride in being at the forefront of the hair care industry.
                    </p>
                    
                </div>
                <div style={{boxShadow:' blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(31, 193, 27) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(255, 217, 19) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px, rgb(255, 156, 85) 30px -30px, rgb(255, 255, 255) 40px -40px 0px -3px, rgb(255, 85, 85) 40px -40px'}} className='md:w-[400px] md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/2.jpg" alt="" /></div>
            </div>
        </div>
    );
}
export default Abouts;


