import React from 'react';



const SkinLightening = () => {
    return (
        <div className='px-6  mycontainer md:px-4 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/5p.jpg" alt="" /></div>
            <div className='flex px-2 mt-10 md:mt-0 flex-col gap-3'>
            <p className="text-lg capitalize text-[#000]">
            Nuevocares – skin lightening serum
          </p>
                <hr />
                <p className='text-sm text-[#777]'>Powered by Tranexamic Acid: This serum is packed with Tranexamic Acid that helps to reduce hyperpigmentation and blemishes. It quickly absorbs into the skin and is safe to be used daily as a de-pigmentation serum. It also evens out skin tone by visibly reducing discolouration.
Fades dark spots and patches: The Rapid Spots Reduction Drops aids to control melanin production via multiple pathways and reduces spots and patches. It also helps with managing photodamage over time. With regular usage, this serum brightens and livens up the skin too.
Improves skin’s radiance: It improves skin texture and strengthens the skin’s barrier significantly to regulate oil production and even out skin tone. Tranexamic Acid, Oligopeptide 68, and Niacinamide act as brightening agents that regulate the dispersion of melanin.
Helps in treating skin conditions: The serum targets hyperpigmentation while also caring for the moisture barrier of the skin. When used in its recommended dosage, this serum helps in treating conditions like PiE, PiH, and Melasma.
Made for all skin types: With added emollients, the Rapid Spots Reduction Drops is gentle on the skin and is suitable for all skin types. This serum works well for Indian skin tones as it targets melanin dispersion, sebum production, and hydration.
Safe & ethical ingredients: Vegan, Cruelty-Free, Sulfate Free, Paraben Free, Dermatologically Tested, and has a pH of 5.5-6.5. This serum is made with Squalane - for hydration, SPF to boost the power of Tranexamic Acid, and Encapsulated Peptides that reach deeper into the skin.
Perform a patch test before using products. Apply a small amount to a tiny skin area and monitor for 24-48 hours. If you experience any redness, itching, swelling, or adverse reactions, consult a dermatologist.</p>
                
            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg capitalize text-[#000]">
            Nuevocares – skin lightening serum
          </p>
                <hr />
                <p className='text-sm text-[#777]'>Powered by Tranexamic Acid: This serum is packed with Tranexamic Acid that helps to reduce hyperpigmentation and blemishes. It quickly absorbs into the skin and is safe to be used daily as a de-pigmentation serum. It also evens out skin tone by visibly reducing discolouration.
Fades dark spots and patches: The Rapid Spots Reduction Drops aids to control melanin production via multiple pathways and reduces spots and patches. It also helps with managing photodamage over time. With regular usage, this serum brightens and livens up the skin too.
Improves skin’s radiance: It improves skin texture and strengthens the skin’s barrier significantly to regulate oil production and even out skin tone. Tranexamic Acid, Oligopeptide 68, and Niacinamide act as brightening agents that regulate the dispersion of melanin.
Helps in treating skin conditions: The serum targets hyperpigmentation while also caring for the moisture barrier of the skin. When used in its recommended dosage, this serum helps in treating conditions like PiE, PiH, and Melasma.
Made for all skin types: With added emollients, the Rapid Spots Reduction Drops is gentle on the skin and is suitable for all skin types. This serum works well for Indian skin tones as it targets melanin dispersion, sebum production, and hydration.
Safe & ethical ingredients: Vegan, Cruelty-Free, Sulfate Free, Paraben Free, Dermatologically Tested, and has a pH of 5.5-6.5. This serum is made with Squalane - for hydration, SPF to boost the power of Tranexamic Acid, and Encapsulated Peptides that reach deeper into the skin.
Perform a patch test before using products. Apply a small amount to a tiny skin area and monitor for 24-48 hours. If you experience any redness, itching, swelling, or adverse reactions, consult a dermatologist.</p>
                
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/5.jpg" alt="" /></div>
        </div>

    </div>
    );
}

export default SkinLightening;
