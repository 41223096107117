import { useState } from 'react';
import { AiFillCaretDown } from "react-icons/ai"
import { Link } from 'react-router-dom';


const Seller = () => {
    const [Toggle, SetToggle] = useState(false);


    const categries = [ ' Pimple Serum', 'Vitamin E Serum', 'Bhringraj Hair Oil', 'Onion Hair Oil','Skin Lightening Serum','Vitamin C Serum']
   

    const products = [
        {
            img: "image/1.jpg",
            name: "nuevocare anti acne pimple serum",
            link: "/pimple_serum"
        },
        {
            img: "image/2.jpg",
            name: "nuevocare vitamin e Serum",
            link: "/vitamin_e"
        },
        {
            img: "image/3.jpg",
            name: "nuevocare Bhringraj hair oil ",
            link: "/bhringraj_Hair_Oil"
        },
        {
            img: "image/4.jpg",
            name: "nuevocare onion hair oil ",
            link: "/onion_hair_oil"
        },
        {
            img: "image/5.jpg",
            name: "nuevocare skin lightening Serum ",
            link: "/skin_lightening"
        },
        {
            img: "image/6.jpg",
            name: "nuevocare vitamin c Serum",
            link: "/vitamin_c"
        }
      
        
    ];

    return (
        <div className='mycontainer px-10'>
  <div className='bg-[white]'>
    <div className='my-5'>

    <div className='md:text-[30px] font-bold text-[18px]  text-[#e1c6cb] text-center'>BEST SELLER</div>
            <hr className='w-[200px] font-bold mx-auto'  style={{ borderBottom: "4px solid #b13244" }}/>
    </div>
           
            <div className='text-2xl w-[300px] mx-auto md:hidden p-2  shadow border flex justify-center'><AiFillCaretDown onClick={() => { SetToggle(!Toggle) }} /></div>
            <ul className='md:flex hidden  justify-center font-semibold text-black   gap-10 mt-4 mb-4 cursor-pointer'>
                {
                    categries.map((d, i) => {
                        return <li key={i} className='hover:underline text-[#b13244]  hover:text-[rgb(226,23,86)]'>{d}</li>
                    })
                }
            </ul>
            <ul className={`md:hidden  justify-center gap-10 mt-4 mb-4 cursor-pointer 
            ${Toggle ? 'h-[350px] opacity-2' : 'h-0 opacity-0'}`}>
                {
                    categries.map((d, i) => {
                        return <li key={i} className='p-1 border text-center  hover:text-[#33A0FF]'>{d}</li>
                    })
                }
            </ul>
            <div className='grid  md:grid-cols-3 px-3 md:px-0 gap-4  md:gap-12 justify-center mb-5'>
                {products.map((data, i) => {
                    return <Box {...data} key={i} />
                })}
            </div>
        </div>
        </div>
      
    );
}

export default Seller;

const Box = (props) => {
    
    return (
        <div style={{boxShadow:'  rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px'}}  className=' p-4 overflow-hidden hover:shadow-lg'>
                <div className='max-w-sm '><img className='h-[100%] transition duration-300 ease-in-out hover:scale-110 w-[100%]' src={props.img} alt="" /></div>
                <div className='text-center'>
                    <h1 className='capitalize  text-[12px] md:text-[16px]  text-black my-4'>{props.name}</h1>
                    <div  class="bg-transparent text-[12px] text-bold md:text-[16px]  hover:bg-black text-[#e21756] font-semibold hover:text-white py-2 my-2 md:px-3 border border-blue-500 hover:border-transparent rounded">
                    <Link to={props.link}>View Full Details</Link>
                    </div>
                  
                </div>
            </div>
    );
};




















