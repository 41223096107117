import React from 'react';


const OnionHairOil = () => {
    return (
        <div className='px-6  mycontainer md:px-4 mb-8'>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='md:w-[400px] sm:w-[200px] flex justify-center items-center' ><img src="image/4.jpg" alt="" /></div>
            <div className='flex mt-10 md:mt-0 px-2 flex-col gap-3'>
            <p className="text-lg capitalize text-[#000]">
            Nuevocares – Onion hair oil 
          </p>
                <hr />
                <p className='text-sm text-[#777]'>Onion hair oil helps to increase the shine of the hair in the moisture, detangle and loosen the tangled ends of hair with its formulation of botanical oils.
Onion oil is rich in sulfur which is manage breakage, spilt ends and thinning of hair, other nutrients present in onion made oxidation of hair, it also maintains the reqular pH of the hair.
How to use - Apply A few drops directly on scalp & massage with fingertips, to help the oil penetrate into roots of your hair. Leave it overnight or for few hours, wash off with a sulfate free shampoo.
Onion hair oil is a non-sticky, non-greasy hair oil for silkier & stronger hair and with its fast absorption, makes it perfect for hair. It can be used for scalp massage also.</p>
            </div>
        </div>
        <div className='grid  text-justify md:grid-cols-2 mt-10'>
            <div className='flex px-2 flex-col gap-3'>
            <p className="text-lg capitalize text-[#000]">
            Nuevocares – Onion hair oil 
          </p>
                <hr />
                <p className='text-sm text-[#777]'>Onion hair oil helps to increase the shine of the hair in the moisture, detangle and loosen the tangled ends of hair with its formulation of botanical oils.
Onion oil is rich in sulfur which is manage breakage, spilt ends and thinning of hair, other nutrients present in onion made oxidation of hair, it also maintains the reqular pH of the hair.
How to use - Apply A few drops directly on scalp & massage with fingertips, to help the oil penetrate into roots of your hair. Leave it overnight or for few hours, wash off with a sulfate free shampoo.
Onion hair oil is a non-sticky, non-greasy hair oil for silkier & stronger hair and with its fast absorption, makes it perfect for hair. It can be used for scalp massage also.</p>
            </div>
            <div className='md:w-[400px] mt-10 md:mt-0 md:ml-20 sm:w-[200px] flex justify-center items-center' ><img src="image/4.jpg" alt="" /></div>
        </div>

    </div>
    );
}

export default OnionHairOil;
