import React from "react";

const BhringrajHairOil = () => {
  return (
    <div className="px-6 mycontainer md:px-4 mb-8">
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="md:w-[400px] myshadow  sm:w-[200px] flex justify-center items-center">
          <img src="image/3p.jpg" alt="" />
        </div>
        <div className="flex px-2 mt-10 md:mt-0 flex-col gap-3">
          <p className="text-lg capitalize text-[#000]">
            Nuevocares – maha bhringraj hair oil
          </p>
          <hr />
          <p className="text-sm text-[#777]">
          ﻿ NUEVOCARE® Directions for use Take a handful of oil, gently
            massage it into the scalp and hair, work from root to tip.
            Concentrate on the ends. Leave on for minimum 30 mins before
            shampoo. For best results Massage the scalp in circular motion for
            10 mins and leave on overnight.
            Sesame Oil, dried herbs of bhringraj, brahmi, amla, harad, bahera, nagarmotha, kachur, lodhra, manjishtha, bavachi, henna, mulethi, jatamassi and hibiscus flowers

Prevents premature graying, falling of hair, sleeplessness and headaches. Combats dandruff, brings our luxuriant hair growth, and improves melanin formation
          </p>
        
        </div>
      </div>
      <div className="grid  text-justify md:grid-cols-2 mt-10">
        <div className="flex px-2 flex-col gap-3">
          <p className="text-lg capitalize text-[#000]">
            Nuevocares – maha bhringraj hair oil
          </p>
          <hr />
          <p className="text-sm text-[#777]">
            ﻿ NUEVOCARE® Directions for use Take a handful of oil, gently
            massage it into the scalp and hair, work from root to tip.
            Concentrate on the ends. Leave on for minimum 30 mins before
            shampoo. For best results Massage the scalp in circular motion for
            10 mins and leave on overnight.
            Sesame Oil, dried herbs of bhringraj, brahmi, amla, harad, bahera, nagarmotha, kachur, lodhra, manjishtha, bavachi, henna, mulethi, jatamassi and hibiscus flowers

Prevents premature graying, falling of hair, sleeplessness and headaches. Combats dandruff, brings our luxuriant hair growth, and improves melanin formation
          </p>
         
        </div>
        <div className="md:w-[400px] mt-10 md:mt-0  md:ml-20 sm:w-[200px] flex justify-center items-center">
          <img src="image/3.jpg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default BhringrajHairOil;
