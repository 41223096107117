import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../component/Nav';
import Footer from '../component/Footer'

const Main =()=> {
  return (
   <>
   <Navbar/> 
   <Outlet/>
   <Footer/>
   </>
  )
}
export default Main
