import React from 'react';
import Middle from '../component/Middle'

const Services = () => {
    return (
      <>
      <Middle/>
      </>
    );
}

export default Services;
