import React, { useState, useEffect } from 'react';
import {FaLessThan,FaGreaterThan} from "react-icons/fa"



const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    'url("image/carosel1.jpg")',
    'url("image/carosel1.jpg")',
    'url("image/carosel1.jpg")',
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  useEffect(() => {
    // Automatically change the slide every 3 seconds (adjust the interval as needed)
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  },[]);
  
  return (
    <div className='mb-6 max-w-mycontainer '>
      <div className='relative'>
        <div className="relative w-full">
          <div
            className="relative  overflow-hidden transition-transform duration-500 ease-in-out"
           style={{
              backgroundImage: slides[currentSlide],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              width: '100%',
              paddingTop: '55.25%', // 16:9 aspect ratio (adjust as needed)
            
            }}
          >
          </div>
          <button
            onClick={prevSlide}
            className="bg-gray-800 absolute top-[50%] left-0 flex items-center cursor-pointer bg-opacity-30 hover:bg-opacity-40 text-white p-2 rounded-full focus:outline-none"
          >
            <FaLessThan className='md:text-[12px] text-[7px]'/>
          </button>

          <button
            onClick={nextSlide}
            className="bg-gray-800 absolute top-[50%] right-0 flex items-center cursor-pointer bg-opacity-30 hover:bg-opacity-40 text-white p-2 rounded-full focus:outline-none"
          >
            <FaGreaterThan className='md:text-[12px] text-[7px]' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
