import React from 'react';
import Carousel from '../component/Carousel'
import Middle from '../component/Middle'

const Home = () => {
  return (
  <>
  <Carousel/>
  <Middle/>
  </>
  )
}

export default Home
