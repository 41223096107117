import React from "react";
import Contacts from "../component/Contacts";

const Contact = () => {
  return (
    <>
      <Contacts />
    </>
  );
};

export default Contact;
