import React,{useContext} from 'react';
import { BsFacebook, BsWhatsapp, BsInstagram, BsYoutube,  } from "react-icons/bs"
import { AiOutlineArrowUp } from "react-icons/ai"

import { Link } from 'react-router-dom';
import { MainContext } from '../contaxt/MainContext';


const Footer = () => {
  const {scrollToTop,MenuItems} = useContext(MainContext);

  const socialMediaLinks = [
    {
        name: 'Facebook',
        icon: BsFacebook,
        url: 'https://www.facebook.com/nuecocare/',
      
    },
    {
        name: 'WhatsApp',
        icon: BsWhatsapp,
        url: 'https://api.whatsapp.com/send/?phone=918770769155&text=Hello%2C+I+am+interested+in your services',
      
    },
    {
        name: 'Instagram',
        icon: BsInstagram,
        url: 'https://www.instagram.com/nuevocare/?hl=en',
      
    }
    
];
const handleSocialClick = (url) => {
  window.location.href = url
}


 
  return (

      <div   style={{ borderTop: "2px solid #b13244" }} className='mt-[50px]  w-mycontainer'>
        <footer className="relative bg-white shadow-lg pt-8 pb-6">
          <div className="container mx-auto px-4">
            <div >
              <div className='grid gap-5 md:grid-cols-3'>
                <div >
                  <h4 className="text-3xl fonat-semibold text-blueGray-700">Let's keep in touch!</h4>
                  <h5 className="text-lg mt-0 mb-2 text-blueGray-600">
                    Find us on any of these platforms, we respond 1-2 business days.
                  </h5>
                  <div className="mt-6  flex lg:mb-0 mb-6 ">
                    {socialMediaLinks.map((socialMedia, index) => (
                      <socialMedia.icon className="shadow-lg transition duration-300 ease-in-out hover:scale-110 font-normal text-[30px] items-center justify-center cursor-pointer align-center rounded-full outline-none focus:outline-none mr-2"
                        key={index}
                        onClick={() => handleSocialClick(socialMedia.url)}
                   
                      />
                    ))}
                  </div>
                </div>
                <div className=' md:ml-10 '>
                  <span className="block uppercase text-md text-[#000000] font-semibold mb-2">Useful Links</span>
                  <ul className="list-unstyled text-[12px]">
                    
                    {
                      MenuItems.map(
                        (menu,index)=>{
                        return  <li key={index}>
                      <Link onClick={scrollToTop}  to={menu.link} className="text-blueGray-600 hover:text-blueGray-800 block pb-2 text-sm">{menu.name}</Link>
                    </li>
                        }
                      )
                    }
                  </ul>
                </div>
                <div>
                  <span className="block uppercase text-[16px] text-[#000000] font-semibold mb-2">Shop Address</span>
                  <ul className="list-unstyled text-sm ">
                    <li> 624, Sudama Nagar Main Rd, Sector D, Bank Colony, Indore, Madhya Pradesh 452009,

India </li>
                   <li className='mt-2'>nuevocare@gmail.com</li>
                    <li className='mt-2'>+91-8770769155</li>
                    <li className='mt-2'>+91-8770811689</li>
                 
                  </ul>
                </div>
              </div>
              <div className="container flex justify-end">
                    <button onClick={scrollToTop} className="scroll-to-top-button   inline-block ">
                        <AiOutlineArrowUp className="w-[30px]  rounded-full border-[#b13244] border  text-center animate-bounce h-[30px] text-[#b13244]" />
                    </button>
                </div>
            </div>
            <hr  className='my-2 ' style={{ borderBottom: "2px solid #b13244" }}/>
            <div className="flex flex-wrap items-center md:justify-between justify-center">
              <div className="w-full md:w-full px-2 mx-auto text-center">
                <div className="text-sm uppercase text-blueGray-500 font-semibold ">
                  COPYRIGHT © 2023 nisha batosiya ALL RIGHTS RESERVED
                </div>
              </div>
            </div>
          </div>
        
        </footer>
      </div>
  );
}

export default Footer;
